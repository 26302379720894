import session from "@/service/sessionService.vue"; 

let path = location.pathname.split('/');
let basePath = location.origin;
let rootPath = '/';

if (path[1] == 'app') {
	basePath += '/app';
	rootPath = '/app/';
	path.splice(1, 1);
}

if (path[1] == 'homolog') {
	basePath += '/homolog';
	rootPath = '/homolog/';
	path.splice(1, 1);
}

if(path[1] == 'webcluster') {
	basePath += '/webcluster';
	rootPath= '/webcluster/';
	path.splice(1, 1);
}

export default {
	core: basePath + "/core/" + path[1] + '/' + path[2] + '/',
	serverAuth: "https://cdn.rein.net.br/app",
	integracaoPath: 'app/integracao/',
	configuracaoPath: 'app/template/',
	loginPath: "//cdn.rein.net.br/webcluster?redirectAngular=1",
	socketKey:"d4a2ec9e35b9213f214a515ed99c954b",
	socketUrl:"https://vision.rein.net.br",
	rootPath,
	strCliente: path[1],
	strVersao: session.versao
};
